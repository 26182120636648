import React from 'react';
import { Link } from 'react-router-dom';

function FAQ() {
  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">
            Frequently Asked Questions
          </h1>
          <p className="text-xl md:text-2xl mb-8 max-w-3xl">
            Find answers to common questions about our endoscopic treatments
          </p>
        </div>
      </header>

      <main className="py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold mb-8">Choose a category:</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <Link to="/faq/gerd" className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow">
                <h3 className="text-xl font-semibold mb-2">GERD Treatment</h3>
                <p>Learn about our innovative GERD management solutions</p>
              </Link>
              <Link to="/faq/bmi" className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow">
                <h3 className="text-xl font-semibold mb-2">Obesity Management</h3>
                <p>Discover our minimally invasive approach to weight loss</p>
              </Link>
              <Link to="/faq/dmr" className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow">
                <h3 className="text-xl font-semibold mb-2">Diabetes Treatment</h3>
                <p>Explore our endoscopic solutions for type 2 diabetes</p>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default FAQ;