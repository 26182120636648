import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-300 py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="mb-8 md:mb-0">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/FINAL%20FILE%20ENDOLASTIC%20GRADATION%20(1)-qzdovPyZBB6orCzw9DZNHsq0FR4Omb.png"
              alt="Endolastic Logo"
              className="h-10 w-auto object-contain mb-4"
            />
            <p className="text-sm">Innovative endoscopic solutions for GERD, obesity, and diabetes management.</p>
          </div>
          <div>
            <h3 className="text-white font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/about" className="hover:text-orange-400 transition-colors">About</Link></li>
              <li><Link to="/privacy-policy" className="hover:text-orange-400 transition-colors">Privacy Policy</Link></li>
              <li><Link to="/terms-of-service" className="hover:text-orange-400 transition-colors">Terms of Service</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white font-semibold mb-4">
              <Link to="/faq" className="hover:text-orange-400 transition-colors">FAQ</Link>
            </h3>
            <ul className="space-y-2">
              <li><Link to="/faq/gerd" className="hover:text-orange-400 transition-colors">GERD</Link></li>
              <li><Link to="/faq/bmi" className="hover:text-orange-400 transition-colors">BMI</Link></li>
              <li><Link to="/faq/dmr" className="hover:text-orange-400 transition-colors">DMR</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white font-semibold mb-4">Connect</h3>
            <ul className="space-y-2">
              <li><a href="https://www.linkedin.com/company/endolastic/" className="hover:text-orange-400 transition-colors">LinkedIn</a></li>
              <li><a href="/endolastic-vs-ozempic" className="hover:text-orange-400 transition-colors">Vs Ozempic</a></li>
              <li><a href="/contact" className="hover:text-orange-400 transition-colors">Contact Us</a></li>
            </ul>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-700 text-center text-sm">
          <p>© {new Date().getFullYear()} Endolastic Inc. All rights reserved.</p>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-700 text-center text-sm">
          <p><a href="https://doihavegerd.com/?utm_source=endolastic&utm_medium=footer" target="_blank" rel="noopener noreferrer">Do I Have GERD?</a> | <a href="https://startuptoscaleup.com/?utm_source=endolastic&utm_medium=footer" target="_blank" rel="noopener noreferrer">StartUp Framework</a> </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;