import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, Check, Download, Phone, Play, ArrowRight } from 'lucide-react';
import t2bwhiteImage from '../assets/images/t2bwhite.png';
import DMRWhitePaperPDF from '../assets/images/DMR-WhitePaper.pdf';
import BandInUseImage from '../assets/images/BandInUse.png';

const comparativeData = [
  { feature: 'Procedure Time', EBDMR: '30-45 min', OralMedications: 'N/A', GLP1ReceptorAgonists: 'N/A', MetabolicSurgery: '2-3 hours' },
  { feature: 'Invasiveness', EBDMR: 'Minimally invasive, endoscopic', OralMedications: 'Non-invasive', GLP1ReceptorAgonists: 'Minimally invasive (injection)', MetabolicSurgery: 'Surgical, highly invasive' },
  { feature: 'Mechanism', EBDMR: 'Restores duodenal signaling', OralMedications: 'Various metabolic effects', GLP1ReceptorAgonists: 'Mimics incretin hormones', MetabolicSurgery: 'Alters GI anatomy' },
  { feature: 'Durability', EBDMR: 'Potential long-term effect', OralMedications: 'Requires continuous use', GLP1ReceptorAgonists: 'Requires continuous use', MetabolicSurgery: 'Long-term, permanent changes' },
  { feature: 'Side Effects', EBDMR: 'Minimal, transient', OralMedications: 'Varies by medication', GLP1ReceptorAgonists: 'GI issues, potential long-term risks', MetabolicSurgery: 'Surgical risks, nutritional deficiencies' },
];

const FAQs = [
  {
    question: 'How does EBDMR compare to other duodenal resurfacing technologies?',
    answer: 'EBDMR uses a mechanical ligation approach, avoiding the need for ablative technologies. This makes the procedure simpler, potentially safer, and more cost-effective compared to other duodenal resurfacing methods.'
  },
  {
    question: 'Can patients stop their diabetes medications after EBDMR?',
    answer: 'While many patients may see a reduction in medication needs, decisions about medication management should be made in consultation with their healthcare provider based on individual response to the treatment.'
  },
  {
    question: 'Is EBDMR reversible?',
    answer: 'EBDMR induces natural tissue regeneration. While the procedure itself is not reversible, it does not permanently alter the anatomy of the digestive system.'
  },
  {
    question: 'How soon can patients expect to see improvements in their diabetes control?',
    answer: 'Early improvements may be seen within weeks, but significant changes in glucose control are typically observed over 3-6 months as the duodenal tissue regenerates and metabolic signaling improves.'
  },
];

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      initial={false}
      animate={{ backgroundColor: isOpen ? "rgba(249, 250, 251, 1)" : "rgba(255, 255, 255, 1)" }}
      className="border border-gray-200 rounded-lg overflow-hidden mb-4"
    >
      <button
        className="flex justify-between items-center w-full text-left px-6 py-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold pr-8">{question}</h3>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronDown className="flex-shrink-0 text-orange-500 w-6 h-6" />
        </motion.div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className="px-6 py-4 text-gray-600">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

function DIABETESProductPage() {
  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl md:text-6xl font-bold mb-6 leading-tight"
          >
            Endolastic EBDMR: Revolutionary Type 2 Diabetes Treatment
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl md:text-2xl mb-8 max-w-3xl"
          >
            Endoscopic Band Duodenal Mucosal Resurfacing - Restoring Metabolic Balance for Diabetes Patients
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <a href="#whitepaper" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-orange-600 bg-white hover:bg-orange-50 transition duration-300">
              <Download className="mr-2 h-5 w-5" />
              Read Whitepaper
            </a>
          </motion.div>
        </div>
      </header>

      <main className="py-16">
        <div className="container mx-auto px-4">
          <section id="intro" className="mb-24">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Transforming Type 2 Diabetes Treatment with Minimally Invasive Technology</h2>
              <p className="text-xl text-gray-700 mb-8">
                The Endolastic Endoscopic Band Duodenal Mucosal Resurfacing (EBDMR) system represents a breakthrough in the treatment of Type 2 Diabetes. By leveraging advanced ligation technology to target the duodenal mucosa, EBDMR offers a safe, effective, and minimally invasive solution for patients struggling with diabetes, providing an alternative to lifelong medication dependence and more invasive surgical options.
              </p>
            </div>
          </section>

          <section id="whitepaper" className="mb-24">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-6 text-center">EBDMR Whitepaper</h2>
              <div className="bg-white rounded-xl shadow-lg overflow-hidden border-2 border-orange-500">
                <div className="flex flex-col md:flex-row">
                  <div className="md:w-1/2 p-6">
                    <img 
                      src={t2bwhiteImage} 
                      alt="Whitepaper Preview" 
                      className="w-full h-auto rounded-lg shadow-md"
                    />
                  </div>
                  <div className="md:w-1/2 p-6 flex flex-col justify-between">
                    <div>
                      <h3 className="text-xl font-semibold mb-4">Duodenal Mucosal Resurfacing (DMR) for Type 2 Diabetes</h3>
                      <p className="text-gray-600 mb-4">
                        Duodenal Mucosal Resurfacing (DMR) is a promising non-pharmacologic intervention for Type 2 Diabetes, targeting underlying duodenal mucosal damage.
                      </p>
                      <ul className="list-disc list-inside text-gray-600 space-y-2 mb-4">
                        <li>Endolastic's approach uses endoscopic band ligation for controlled mechanical compression of superficial mucosal layers, offering a safer alternative to ablation methods.</li>
                        <li>DMR aims to regenerate healthier duodenal tissue and improve glycemic control, addressing underlying pathophysiology that drugs often miss.</li>
                        <li>The method leverages natural healing processes, potentially regenerating insulin-sensitive tissue without ablation risks, offering a safer T2D management solution.</li>
                      </ul>
                    </div>
                    <a 
                      href={DMRWhitePaperPDF} 
                      download
                      className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 transition duration-300"
                    >
                      <Download className="mr-2 h-5 w-5" />
                      Download Whitepaper
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="how-it-works" className="mb-24">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-12 text-center">How EBDMR Works</h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div>
                  <img
                    src={BandInUseImage}
                    alt="EBDMR Procedure Illustration"
                    className="rounded-xl shadow-lg w-full"
                  />
                </div>
                <div>
                  <ol className="space-y-6">
                    {[
                      { title: "Precision Targeting", description: "The EBDMR system precisely targets the duodenal mucosa." },
                      { title: "Tissue Capture", description: "Using our proprietary SafeTrap™ barrels, the system captures the targeted mucosa." },
                      { title: "Band Application", description: "Our unique WindowLock™ bands are applied to induce controlled pressure necrosis." },
                      { title: "Tissue Regeneration", description: "Over the next few weeks, the dysfunctional duodenal tissue is replaced by new, healthy tissue." },
                      { title: "Metabolic Reset", description: "The regenerated tissue restores healthy metabolic signaling, potentially leading to better glucose regulation." },
                    ].map((step, index) => (
                      <li key={index} className="flex items-start">
                        <span className="flex items-center justify-center flex-shrink-0 w-8 h-8 text-white bg-orange-500 rounded-full mr-4 font-bold">
                          {index + 1}
                        </span>
                        <div>
                          <h3 className="font-semibold text-lg mb-1">{step.title}</h3>
                          <p className="text-gray-600">{step.description}</p>
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section id="clinical-outcomes" className="mb-24 bg-gradient-to-br from-orange-500 to-orange-600 text-white py-16 rounded-2xl">
            <div className="max-w-4xl mx-auto px-4">
              <h2 className="text-3xl font-bold mb-12 text-center">Expected Clinical Outcomes</h2>
              <div className="grid md:grid-cols-2 gap-8">
                {[
                  { label: "HbA1c Reduction", value: "Targeting average reduction of 1.5-2% at 6 months" },
                  { label: "Insulin Sensitivity", value: "Significant improvement in insulin sensitivity measures" },
                  { label: "Medication Reduction", value: "Potential reduction or elimination of diabetes medications" },
                  { label: "Weight Loss", value: "Moderate weight loss as a secondary benefit" },
                  { label: "Quality of Life", value: "Marked improvement in diabetes-related quality of life scores" },
                  { label: "Safety Profile", value: "Minimal complication rate, no reported severe adverse events" },
                ].map((outcome, index) => (
                  <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg">
                    <h3 className="text-xl font-semibold mb-2">{outcome.label}</h3>
                    <p>{outcome.value}</p>
                  </div>
                ))}
              </div>
              <div className="mt-12 text-center">
                <a href="#" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-orange-600 bg-white hover:bg-orange-50 transition duration-300">
                  <Download className="mr-2 h-5 w-5" />
                  Download Full Clinical Data
                </a>
              </div>
            </div>
          </section>

          <section id="patient-selection" className="mb-24">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-12 text-center">Ideal Patient Selection</h2>
              <ul className="list-disc list-inside space-y-4">
                <li>Patients with Type 2 Diabetes not achieving adequate glycemic control despite medications and lifestyle changes</li>
                <li>HbA1c levels between 7.5% and 10%</li>
                <li>BMI between 28 and 40</li>
                <li>Patients seeking to reduce medication dependence</li>
                <li>Individuals looking for a minimally invasive alternative to metabolic surgery</li>
              </ul>
            </div>
          </section>

          <section id="comparative-advantages" className="mb-24">
            <div className="max-w-6xl mx-auto">
              <h2 className="text-3xl font-bold mb-12 text-center">Advantages Over Other Diabetes Treatments</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-lg">
                  <thead className="bg-orange-500 text-white">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Feature</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">EBDMR</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Oral Medications</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">GLP-1 Receptor Agonists</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Metabolic Surgery</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {comparativeData.map((row, index) => (
                      <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                        <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">{row.feature}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{row.EBDMR}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{row.OralMedications}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{row.GLP1ReceptorAgonists}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{row.MetabolicSurgery}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>

          <section id="faq" className="mb-24">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
              <div className="space-y-4">
                {FAQs.map((faq, index) => (
                  <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
              </div>
              <div className="mt-8 text-center">
                <a
                  href="/faq/dmr"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-orange-600 hover:bg-orange-700 transition duration-300"
                >
                  <ArrowRight className="mr-2 h-5 w-5" />
                  View Full FAQ
                </a>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}

export default DIABETESProductPage;