import React from 'react';
import { motion } from 'framer-motion';

function Contact() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-orange-50 to-white text-gray-900">
      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl md:text-6xl font-bold mb-6 leading-tight"
          >
            Contact Endolastic
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl md:text-2xl mb-8 max-w-3xl"
          >
            We're here to answer your questions and provide more information about our innovative solutions.
          </motion.p>
        </div>
      </header>

      <main className="py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-2xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-4xl font-bold mb-8">Get in Touch</h2>
              <p className="text-xl mb-8">
                Enter your details below, and one of our team members will reach out to you shortly.
              </p>
              <form action="https://app.convertkit.com/forms/7175340/subscriptions" className="seva-form formkit-form" method="post" data-sv-form="7175340" data-uid="bc94d309ba" data-format="inline" data-version="5" min-width="400 500 600 700 800" style={{backgroundColor: "rgb(255, 255, 255)", borderRadius: "6px"}}>
                <div data-style="clean">
                  <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
                  <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields">
                    <div className="formkit-field mb-4">
                      <input className="formkit-input w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500" aria-label="First Name" name="fields[first_name]" placeholder="First Name" type="text" style={{color: "rgb(0, 0, 0)", borderColor: "rgb(227, 227, 227)", borderRadius: "4px", fontWeight: 400}} />
                    </div>
                    <div className="formkit-field mb-4">
                      <input className="formkit-input w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500" name="email_address" aria-label="Email Address" placeholder="Email Address" required="" type="email" style={{color: "rgb(0, 0, 0)", borderColor: "rgb(227, 227, 227)", borderRadius: "4px", fontWeight: 400}} />
                    </div>
                    <button data-element="submit" className="formkit-submit formkit-submit w-full bg-orange-600 text-white font-bold py-2 px-4 rounded-md hover:bg-orange-700 transition duration-300">
                      <div className="formkit-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <span>Send Message</span>
                    </button>
                  </div>
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Contact;