import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Award, Zap, TrendingUp, Users, Lightbulb, BarChart, Target, Star } from 'lucide-react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom

const benefitItems = [
  { icon: Award, title: "Enhance Patient Outcomes", description: "Access cutting-edge protocols and treatment modalities" },
  { icon: TrendingUp, title: "Boost Your Practice's Reputation", description: "Gain recognition as a leader in GERD, Obesity, and Diabetes care" },
  { icon: Zap, title: "Stay Ahead of the Curve", description: "Be among the first to implement innovative approaches" },
];

const offeringItems = [
  { icon: Users, title: "Comprehensive Training and Support", description: "Intensive, specialized training for you and your staff" },
  { icon: Lightbulb, title: "Advanced Tools and Resources", description: "State-of-the-art diagnostic and treatment technologies" },
  { icon: BarChart, title: "Marketing and Patient Acquisition Support", description: "Listing in our network of Centers of Excellence" },
  { icon: Target, title: "Collaborative Network", description: "Join a community of like-minded healthcare providers" },
];

const earlyAdopterItems = [
  { title: "Accelerated Onboarding", description: "Fast-track implementation of our protocols" },
  { title: "Influence Program Development", description: "Shape the evolution of our Centers of Excellence program" },
  { title: "Exclusive Beta Access", description: "Be the first to try new tools and technologies" },
  { title: "Founders' Circle Membership", description: "Join an elite group of pioneering healthcare providers" },
];

function COE() {
  const [activeTab, setActiveTab] = useState("why");

  return (
    <div className="min-h-screen bg-gradient-to-b from-orange-50 to-white text-gray-900">
      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl md:text-6xl font-bold mb-6 leading-tight text-center"
          >
            Become a Center of Excellence
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl md:text-2xl mb-8 max-w-3xl mx-auto text-center"
          >
            Leading the Way in GERD, Obesity, and Diabetes Care
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="flex justify-center"
          >
            <button className="bg-white text-orange-600 hover:bg-orange-100 text-lg px-8 py-3 rounded-full">
              Join Our Network
            </button>
          </motion.div>
        </div>
      </header>

      <main className="py-16">
        <div className="container mx-auto px-4">
          <div className="mb-16">
            <div className="grid w-full grid-cols-3 mb-8">
              {["why", "what", "early"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`py-2 ${activeTab === tab ? 'bg-orange-500 text-white' : 'bg-gray-200'}`}
                >
                  {tab === "why" ? "Why Become a CoE?" : tab === "what" ? "What You'll Get" : "Early Adopter Advantages"}
                </button>
              ))}
            </div>
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                {activeTab === "why" && (
                  <div className="grid md:grid-cols-3 gap-8">
                    {benefitItems.map((item, index) => (
                      <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                        <item.icon className="h-12 w-12 text-orange-500 mb-4" />
                        <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                        <p className="text-gray-600">{item.description}</p>
                      </div>
                    ))}
                  </div>
                )}
                {activeTab === "what" && (
                  <div className="grid md:grid-cols-2 gap-8">
                    {offeringItems.map((item, index) => (
                      <div key={index} className="bg-white p-6 rounded-lg shadow-md flex items-start">
                        <item.icon className="h-8 w-8 text-orange-500 mr-4 flex-shrink-0" />
                        <div>
                          <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                          <p className="text-gray-600">{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {activeTab === "early" && (
                  <div className="bg-white p-6 rounded-lg shadow-md">
                    <h3 className="text-2xl font-semibold mb-4 text-center">Early Adopter Advantages</h3>
                    <div className="grid md:grid-cols-2 gap-6">
                      {earlyAdopterItems.map((item, index) => (
                        <div key={index} className="flex items-start">
                          <Star className="h-6 w-6 text-orange-500 mr-2 flex-shrink-0" />
                          <div>
                            <h4 className="font-semibold mb-1">{item.title}</h4>
                            <p className="text-sm text-gray-600">{item.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </motion.div>
            </AnimatePresence>
          </div>

          <section className="mb-16 bg-gradient-to-r from-orange-100 to-orange-200 rounded-2xl p-8">
            <h2 className="text-3xl font-bold mb-6 text-center">Pricing and Incentives</h2>
            <p className="text-lg text-center mb-8">
              We believe in rewarding early adopters and those committed to excellence. Our pricing model is designed to align with your success.
            </p>
            <div className="grid md:grid-cols-3 gap-6">
              {["Performance-based incentives", "Volume-driven benefits", "Long-term partnership advantages"].map((item, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
                  <h3 className="font-semibold mb-2">{item}</h3>
                </div>
              ))}
            </div>
          </section>

          <section className="text-center">
            <h2 className="text-3xl font-bold mb-6">Ready to Lead the Way?</h2>
            <p className="text-xl mb-8 max-w-3xl mx-auto">
              Join us in revolutionizing the management of GERD, Obesity, and Diabetes. Become a Center of Excellence today and start providing unparalleled care to your patients.
            </p>
            <Link to="/contact" className="inline-block bg-orange-600 hover:bg-orange-700 text-white text-lg px-8 py-3 rounded-full">
              Contact Us to Learn More
            </Link>
            <p className="mt-4 text-sm text-gray-600">
              Spaces in our early adopter program are limited. Reach out now to secure your position as a leader in healthcare innovation.
            </p>
          </section>
        </div>
      </main>
    </div>
  );
}

export default COE;