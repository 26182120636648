import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, Check, Download, Phone, Play, ArrowRight, BookOpen, Users } from 'lucide-react';
import ARBMVideo from '../assets/images/ARBMvideo.mp4';
import BandInUseImage from '../assets/images/BandInUse.png';

const comparativeData = [
  { feature: 'Procedure Time', ARBM: '30-45 min', NissenFundoplication: '1-2 hours', LINX: '1 hour', TIF: '45-60 min' },
  { feature: 'Invasiveness', ARBM: 'Minimally invasive, endoscopic', NissenFundoplication: 'Surgical, laparoscopic', LINX: 'Surgical, laparoscopic', TIF: 'Minimally invasive, endoscopic' },
  { feature: 'Recovery Time', ARBM: 'Same day', NissenFundoplication: '1-3 weeks', LINX: '1-2 weeks', TIF: '2-3 days' },
  { feature: 'Reversibility', ARBM: 'Fully reversible', NissenFundoplication: 'Not reversible', LINX: 'Removable, but surgical', TIF: 'Potentially reversible' },
  { feature: 'Side Effects', ARBM: 'Minimal, transient', NissenFundoplication: 'Gas-bloat syndrome, dysphagia', LINX: 'Dysphagia, inability to belch', TIF: 'Mild dysphagia, bloating' },
];

const FAQs = [
  {
    question: 'How long does the ARBM procedure take?',
    answer: 'The typical ARBM procedure takes between 30-45 minutes.'
  },
  {
    question: 'Is ARBM covered by insurance?',
    answer: 'Many insurance providers cover ARBM as a treatment for chronic GERD. Coverage may vary, so its best to check with individual insurance providers.'
  },
  {
    question: 'How soon can patients return to normal activities?',
    answer: 'Most patients can return to normal activities within 24-48 hours after the procedure.'
  },
  {
    question: 'Is special equipment required to perform ARBM?',
    answer: 'ARBM can be performed using standard endoscopic equipment, with the addition of our proprietary SafeTrap™ barrel and WindowLock™ bands.'
  },
];

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      initial={false}
      animate={{ backgroundColor: isOpen ? "rgba(249, 250, 251, 1)" : "rgba(255, 255, 255, 1)" }}
      className="border border-gray-200 rounded-lg overflow-hidden mb-4"
    >
      <button
        className="flex justify-between items-center w-full text-left px-6 py-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold pr-8">{question}</h3>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronDown className="flex-shrink-0 text-orange-500 w-6 h-6" />
        </motion.div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className="px-6 py-4 text-gray-600">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

function GERDProductPage() {
  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl md:text-6xl font-bold mb-6 leading-tight"
          >
            Revolutionizing GERD Treatment
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl md:text-2xl mb-8 max-w-3xl"
          >
            Endolastic ARBM: Anti-Reflux Band Mucosectomy
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <a href="#video-demo" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-orange-600 bg-white hover:bg-orange-50 transition duration-300">
              <Play className="mr-2 h-5 w-5" />
              Watch Video
            </a>
          </motion.div>
        </div>
      </header>

      <main className="py-16">
        <div className="container mx-auto px-4">
          <section id="intro" className="mb-24">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Transforming GERD Treatment with Minimally Invasive Technology</h2>
              <p className="text-xl text-gray-700 mb-8">
                The Endolastic Anti-Reflux Band Mucosectomy (ARBM) system represents a breakthrough in the treatment of Gastroesophageal Reflux Disease (GERD). By leveraging advanced ligation technology, ARBM offers a safe, effective, and minimally invasive solution for patients suffering from chronic GERD.
              </p>
              <div className="flex flex-wrap justify-center gap-4">
                <a href="#how-it-works" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-orange-600 hover:bg-orange-700 transition duration-300">
                  Learn How It Works
                </a>
                <a href="#clinical-outcomes" className="inline-flex items-center px-6 py-3 border border-gray-300 text-base font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition duration-300">
                  View Clinical Outcomes
                </a>
              </div>
            </div>
          </section>

          <section id="video-demo" className="mb-24">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-6 text-center">See ARBM in Action</h2>
              <div className="relative" style={{ paddingBottom: '56.25%', height: 0 }}>
                <video
                  src={ARBMVideo}
                  controls
                  className="absolute top-0 left-0 w-full h-full rounded-xl shadow-2xl"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </section>

          <section id="how-it-works" className="mb-24">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-12 text-center">How ARBM Works</h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div>
                  <img
                    src={BandInUseImage}
                    alt="ARBM Procedure Illustration"
                    className="rounded-xl shadow-lg w-full"
                  />
                </div>
                <div>
                  <ol className="space-y-6">
                    {[
                      { title: "Precision Targeting", description: "The ARBM system precisely targets the gastroesophageal junction (GEJ)." },
                      { title: "Tissue Capture", description: "Using our proprietary SafeTrap™ barrel, the system captures a controlled amount of tissue, including the mucosal and submucosal layers." },
                      { title: "Band Application", description: "Our unique WindowLock™ bands are applied to create controlled pressure zones." },
                      { title: "Tissue Remodeling", description: "Over time, the banded tissue undergoes controlled ischemia, leading to fibrosis and remodeling of the GEJ." },
                      { title: "LES Restoration", description: "The remodeled tissue enhances the function of the lower esophageal sphincter (LES), effectively reducing reflux." },
                    ].map((step, index) => (
                      <li key={index} className="flex items-start">
                        <span className="flex items-center justify-center flex-shrink-0 w-8 h-8 text-white bg-orange-500 rounded-full mr-4 font-bold">
                          {index + 1}
                        </span>
                        <div>
                          <h3 className="font-semibold text-lg mb-1">{step.title}</h3>
                          <p className="text-gray-600">{step.description}</p>
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section id="technical-specs" className="mb-24 bg-white py-16 rounded-2xl shadow-lg">
            <div className="max-w-4xl mx-auto px-4">
              <h2 className="text-3xl font-bold mb-12 text-center">Technical Specifications</h2>
              <div className="grid md:grid-cols-2 gap-8">
                {[
                  { label: "SafeTrap™ Barrel Diameter", value: "15mm" },
                  { label: "Tissue Capture Depth", value: "Up to 13mm" },
                  { label: "WindowLock™ Band Material", value: "Proprietary biocompatible elastomer" },
                  { label: "Band Retention Force", value: "Optimized for GEJ tissue" },
                  { label: "Endoscope Compatibility", value: "9.8mm - 11.5mm diameter" },
                  { label: "Sterile Packaging", value: "Single-use, ethylene oxide sterilized" },
                ].map((spec, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-lg">
                    <h3 className="text-lg font-semibold mb-2">{spec.label}</h3>
                    <p className="text-gray-700">{spec.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section id="procedure-details" className="mb-24">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-12 text-center">Procedure Details</h2>
              <div className="grid md:grid-cols-3 gap-8">
                {[
                  { icon: "⏱️", label: "Duration", value: "30-45 minutes" },
                  { icon: "💉", label: "Anesthesia", value: "Conscious sedation" },
                  { icon: "🏠", label: "Recovery", value: "Same day discharge" },
                  { icon: "🔄", label: "Bands Applied", value: "3-5 bands" },
                  { icon: "📅", label: "Follow-up", value: "2 weeks, 3 and 6 months" },
                ].map((detail, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
                    <span className="text-4xl mb-4 block">{detail.icon}</span>
                    <h3 className="font-semibold mb-2">{detail.label}</h3>
                    <p className="text-gray-600">{detail.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section id="clinical-outcomes" className="mb-24 bg-gradient-to-br from-orange-500 to-orange-600 text-white py-16 rounded-2xl">
            <div className="max-w-4xl mx-auto px-4">
              <h2 className="text-3xl font-bold mb-12 text-center">Clinical Outcomes</h2>
              <div className="grid md:grid-cols-2 gap-8">
                {[
                  { label: "Symptom Improvement", value: "85% of patients report significant reduction at 6 months" },
                  { label: "PPI Reduction", value: "70% of patients able to discontinue or reduce PPI use" },
                  { label: "Quality of Life", value: "50% average improvement in GERD-HRQL scores at 6 months" },
                  { label: "Durability", value: "80% of patients show sustained results at 2-year follow-up" },
                  { label: "Safety Profile", value: "Less than 2% minor complication rate" },
                ].map((outcome, index) => (
                  <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg">
                    <h3 className="text-xl font-semibold mb-2">{outcome.label}</h3>
                    <p>{outcome.value}</p>
                  </div>
                ))}
              </div>
              <div className="mt-12 text-center">
                <a href="#" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-orange-600 bg-white hover:bg-orange-50 transition duration-300">
                  <Download className="mr-2 h-5 w-5" />
                  Download Full Clinical Data
                </a>
              </div>
            </div>
          </section>

          <section id="patient-selection" className="mb-24">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-12 text-center">Ideal Patient Selection</h2>
              <div className="bg-white p-8 rounded-2xl shadow-lg">
                <ul className="space-y-4">
                  {[
                    "Patients with chronic GERD symptoms despite optimal PPI therapy",
                    "GERD confirmed by 24-hour pH monitoring or impedance testing",
                    "Patients with hiatal hernias <3cm",
                    "BMI <35 (for optimal visualization and tissue capture)",
                    "Absence of Barrett's esophagus or esophageal strictures",
                  ].map((criterion, index) => (
                    <li key={index} className="flex items-start">
                      <Check className="flex-shrink-0 text-orange-500 w-6 h-6 mr-4" />
                      <span>{criterion}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>

          <section id="comparative-advantages" className="mb-24">
            <div className="max-w-6xl mx-auto">
              <h2 className="text-3xl font-bold mb-12 text-center">Advantages Over Other GERD Treatments</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-lg">
                  <thead className="bg-orange-500 text-white">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Feature</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">ARBM</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Nissen Fundoplication</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">LINX</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">TIF</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {comparativeData.map((row, index) => (
                      <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                        <td className="px-6 py-4 whitespace-nowrap font-medium">{row.feature}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{row.ARBM}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{row.NissenFundoplication}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{row.LINX}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{row.TIF}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>

          <section id="training-support" className="mb-24 bg-gray-100 py-16 rounded-2xl">
            <div className="max-w-4xl mx-auto px-4">
              <h2 className="text-3xl font-bold mb-12 text-center">Training and Support</h2>
              <div className="grid md:grid-cols-2 gap-8">
                {[
                  { icon: BookOpen, title: "Initial Training", description: "Comprehensive 2-day hands-on training program" },
                  { icon: Users, title: "Ongoing Support", description: "Access to our team of clinical specialists" },
                ].map((item, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
                    <item.icon className="h-12 w-12 text-orange-500 mb-4 mx-auto" />
                    <h3 className="font-semibold mb-2">{item.title}</h3>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section id="economic-benefits" className="mb-24">
            {/* ... (economic benefits content) ... */}
          </section>

          <section id="distributor-info" className="mb-24 bg-orange-50 py-16 rounded-2xl">
            {/* ... (distributor info content) ... */}
          </section>

          <section id="regulatory-info" className="mb-24">
            {/* ... (regulatory info content) ... */}
          </section>

          <section id="testimonials" className="mb-24">
            {/* ... (testimonials content) ... */}
          </section>

          <section id="faq" className="mb-24">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
              <div className="space-y-4">
                {FAQs.map((faq, index) => (
                  <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
              </div>
              <div className="mt-8 text-center">
                <a
                  href="/faq/gerd"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-orange-600 hover:bg-orange-700 transition duration-300"
                >
                  <ArrowRight className="mr-2 h-5 w-5" />
                  View Full FAQ
                </a>
              </div>
            </div>
          </section>

          <section id="contact" className="bg-orange-50 py-16 rounded-2xl">
            {/* ... (contact content) ... */}
          </section>
        </div>
      </main>

      {/* Footer is handled by the main layout, so we don't include it here */}
    </div>
  );
}

export default GERDProductPage;