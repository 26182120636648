import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';
// Remove this import if it's still present
// import Footer from '../components/Footer';

const faqs = [
  {
    question: "What is ARBM and how does it treat GERD?",
    answer: "ARBM (Anti-Reflux Band Mucosectomy) is a minimally invasive, endoscopic procedure designed to treat Gastroesophageal Reflux Disease (GERD). The procedure uses WindowLock™ bands to reshape and regenerate the lower esophageal sphincter (LES). This remodeling process replaces dysfunctional LES tissue with healthy, newly regenerated tissue, restoring its function and preventing acid reflux. Dr. Jose Nieto's clinical trials have validated the effectiveness of ARBM. In his study of four patients, published in GIE, with refractory GERD, all participants experienced significant reductions in acid exposure and DeMeester scores, allowing them to discontinue proton pump inhibitor (PPI) therapy within four weeks."
  },
  {
    question: "Why does the LES become dysfunctional in GERD patients?",
    answer: "The lower esophageal sphincter (LES) is a ring of muscle that prevents stomach acid from flowing back into the esophagus. In GERD patients, the LES often becomes dysfunctional due to factors like:\n• Decreased LES pressure, caused by certain foods, medications, or conditions like obesity and pregnancy, leading to improper closure.\n• Hiatal hernia, where part of the stomach pushes up into the chest, weakening the LES's barrier function.\n• Increased intra-abdominal pressure, common in obesity, which forces stomach contents upward into the esophagus.\nOver time, this results in chronic acid exposure to the esophagus, leading to inflammation, mucosal damage, and persistent symptoms of acid reflux."
  },
  {
    question: "Who is an ideal candidate for ARBM?",
    answer: "ARBM is ideal for patients with refractory GERD—those who have not responded well to proton pump inhibitors (PPIs) or other GERD treatments. Candidates should also not have a hiatal hernia larger than 2 cm, as larger hernias can interfere with the remodeling of the LES. Most refractory GERD sufferers fall into this candidate profile."
  },
  {
    question: "How does ARBM work, and what are the procedural steps?",
    answer: "The ARBM procedure involves placing WindowLock™ bands around the gastroesophageal junction (GEJ) to restore the function of the LES. The steps are:\n1. Endoscope insertion: An adult gastroscope is introduced to access the GEJ.\n2. Tissue capture: Using SafeTrap™ barrels, the targeted tissue is suctioned into the barrel.\n3. Band deployment: WindowLock™ bands are applied to compress and reshape the tissue, encouraging healthy regeneration.\n4. Tissue remodeling: Over the next few weeks, the dysfunctional tissue is replaced by newly regenerated, healthy tissue, reshaping the LES into a functional barrier that prevents reflux."
  },
  {
    question: "What are the advantages of ARBM in an ASC (Ambulatory Surgical Center) setting?",
    answer: "ARBM is performed under conscious sedation, which allows for quicker recovery and makes it suitable for Ambulatory Surgical Centers (ASCs). Conscious sedation is safer than general anesthesia, especially for high-risk patients, and reduces costs. ARBM's straightforward nature enables it to be performed in ASCs, increasing accessibility."
  },
  {
    question: "How does ARBM compare to other GERD treatments like ARMS, TIF, or Stretta?",
    answer: "ARBM is an evolution of the Anti-Reflux Mucosal Resection (ARMS) procedure, which uses cap and snare techniques to resect tissue. While ARMS is effective, it carries higher risks of complications such as bleeding and stricture formation due to tissue resection. ARBM avoids resection altogether, making it a safer option that still achieves robust LES remodeling through band ligation.\n• ARBM is faster, with procedure times ranging from 6-15 minutes compared to the lengthier ARMS.\n• Dr. Nieto's clinical data highlights ARBM's safety and effectiveness, with no adverse events reported during or after the procedure, and all patients able to stop PPIs within four weeks.\n• Compared to TIF and Stretta, ARBM avoids thermal energy and is less technically demanding, as most endoscopists are already familiar with band ligation techniques."
  },
  {
    question: "What is the mechanism of tissue regeneration after ARBM?",
    answer: "ARBM works by inducing controlled pressure necrosis through the WindowLock™ bands, which initiates a natural healing process. This process stimulates mesenchymal stem cells (MSCs) and fibroblasts to replace the necrotic tissue with healthy, functional tissue, restoring the LES's barrier function."
  },
  {
    question: "How does ARBM improve the function of the LES?",
    answer: "The WindowLock™ bands apply calibrated pressure to the GEJ, reshaping the LES through tissue regeneration. Unlike traditional treatments that simply aim to tighten the LES, ARBM promotes the natural formation of functional tissue, replacing the damaged LES with a stronger, healthier one. This results in a long-lasting restoration of LES function, reducing GERD symptoms and eliminating the need for medications like PPIs."
  },
  {
    question: "How does ARBM ensure effective tissue remodeling without slippage?",
    answer: "The WindowLock™ bands feature an innovative design that locks the tissue in place, preventing slippage during the remodeling process. By securely holding the tissue, the bands ensure consistent pressure, which is critical for uniform tissue regeneration. This leads to more effective and durable results compared to other procedures that may struggle with tissue retention."
  },
  {
    question: "What are the recovery expectations and long-term outcomes of ARBM?",
    answer: "Most patients resume normal activities within 24 to 48 hours after ARBM. Full LES remodeling occurs over 2-4 weeks, during which the dysfunctional tissue is replaced by newly generated, healthy tissue. Dr. Nieto's study demonstrated that patients experienced significant symptom relief, with all patients in his case series discontinuing PPI therapy within four weeks. Long-term studies have shown that ARBM provides sustained symptom relief."
  }
];

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold pr-8">{question}</h3>
        {isOpen ? (
          <ChevronUp className="flex-shrink-0 text-orange-500" />
        ) : (
          <ChevronDown className="flex-shrink-0 text-orange-500" />
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className="mt-2 text-gray-600 whitespace-pre-line">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

function GERDFAQ() {
  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">
            GERD Treatment FAQ
          </h1>
          <p className="text-xl md:text-2xl mb-8 max-w-3xl">
            Frequently Asked Questions for Physicians Regarding Endolastic Therapy for GERD Management
          </p>
        </div>
      </header>

      <main className="py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            {faqs.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        </div>
      </main>

      {/* Make sure there's no Footer component here */}
    </div>
  );
}

export default GERDFAQ;