import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';
// Remove this import
// import Footer from '../components/Footer';

const faqs = [
  {
    question: "What is EBG, and how does it help treat obesity?",
    answer: "EBG (Endolastic Band Gastroplasty) is a minimally invasive endoscopic procedure designed to treat obesity by reshaping the stomach using WindowLock™ bands. These bands are applied to the gastric folds along the greater curvature of the stomach, creating a sleeve-like structure that reduces stomach volume. By restricting stomach expansion, EBG limits food intake and promotes earlier satiety, leading to significant weight loss. Dr. Mohamed Abeid pioneered the use of this method, demonstrating its effectiveness in inducing weight loss with fewer complications than surgical bariatric options."
  },
  {
    question: "Why does creating a sleeve from the greater curvature of the stomach help with weight loss?",
    answer: "The greater curvature of the stomach is responsible for much of the stomach's expansive capacity. By strategically placing bands along this region, EBG mimics the effect of surgical sleeve gastrectomy but without cutting or removing any stomach tissue. The bands induce controlled pressure necrosis and fibrosis, reshaping the stomach into a smaller, more restrictive form. This limits the stomach's ability to stretch, thereby reducing hunger and food intake."
  },
  {
    question: "How does EBG compare to other obesity treatments like intragastric balloons (IGB), GLP-1 inhibitors, and Endoscopic Sleeve Gastroplasty (ESG)?",
    answer: "EBG offers a superior alternative in several key areas:\n• Intragastric Balloons (IGB) are temporary, require removal, and often cause discomfort. EBG, on the other hand, is a permanent solution without the need for device removal.\n• GLP-1 inhibitors, like semaglutide, are costly long-term treatments (up to $10,000 per year) and come with risks like pancreatitis and cardiovascular issues. EBG requires only one procedure, reducing the long-term financial burden.\n• Endoscopic Sleeve Gastroplasty (ESG) uses suturing to create a similar effect but is more technically demanding and expensive. EBG is quicker and more accessible, requiring less physician training."
  },
  {
    question: "How does EBG compare to traditional bariatric surgeries like gastric sleeve or bypass?",
    answer: "Unlike surgical procedures such as gastric sleeve or gastric bypass, EBG does not involve cutting, stapling, or rerouting of the digestive tract. This minimizes the risks of post-surgical complications such as leaks, infections, and nutrient malabsorption. Furthermore, EBG preserves the natural structure of the gastrointestinal tract, which significantly reduces the risk of certain cancers associated with bariatric surgeries, such as esophageal and colorectal cancer."
  },
  {
    question: "How does tissue remodeling work in EBG after the bands are applied?",
    answer: "Once the WindowLock™ bands are applied, they cause controlled pressure necrosis, which stimulates the body's natural healing process. Over time, stem cell-regulated mechanisms promote tissue regeneration and fibrosis, forming a new, healthy tissue structure that reduces the stomach's capacity. This process is safe and durable, ensuring long-term weight loss."
  },
  {
    question: "What role do SafeTrap™ barrels play in the EBG procedure?",
    answer: "The SafeTrap™ barrels used in EBG are designed to capture large volumes of tissue from the deeper layers of the stomach, including the muscularis propria. This deeper engagement is crucial for long-term results, as it enhances the remodeling process and ensures the bands stay securely in place. The innovative design reduces the risk of slippage and allows for a more uniform reduction of stomach volume."
  },
  {
    question: "What makes EBG a safe option for obesity management?",
    answer: "EBG has a remarkably low risk profile, especially when compared to surgical options. The procedure avoids cutting or removing tissue, which significantly reduces the likelihood of complications like bleeding, infection, or leaks. Clinical studies, including those led by Dr. Mohamed Abeid, demonstrate the procedure's effectiveness in achieving significant weight loss with minimal side effects."
  },
  {
    question: "Who is an ideal candidate for the EBG procedure?",
    answer: "EBG is ideal for patients with a Body Mass Index (BMI) ≥ 30 who prefer a minimally invasive, non-surgical weight loss solution. It is especially suitable for individuals who are not candidates for traditional bariatric surgery or who prefer to avoid the risks associated with more invasive procedures. Additionally, it can benefit patients who have regained weight after bariatric surgery."
  },
  {
    question: "What are the long-term benefits of EBG compared to pharmacological treatments?",
    answer: "EBG offers sustained weight loss with just one procedure, eliminating the need for long-term medication use. In contrast, pharmacological treatments like GLP-1 inhibitors require lifelong administration and come with a risk of side effects such as pancreatitis and cardiovascular issues. EBG avoids these risks while providing durable weight loss results."
  },
  {
    question: "Can EBG be repeated if necessary?",
    answer: "Yes, one of the key advantages of EBG is its flexibility. If necessary, the procedure can be repeated to enhance or maintain weight loss results, much like other endoscopic banding techniques."
  }
];

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4 border-b border-gray-200 pb-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-xl font-semibold">{question}</h3>
        {isOpen ? (
          <ChevronUp className="w-6 h-6 text-orange-500" />
        ) : (
          <ChevronDown className="w-6 h-6 text-orange-500" />
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className="mt-2 text-gray-600">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

function BMIFAQ() {
  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">
            EBG Treatment FAQ
          </h1>
          <p className="text-xl md:text-2xl mb-8 max-w-3xl">
            Frequently Asked Questions for Physicians Regarding Endolastic Band Gastroplasty for Obesity Management
          </p>
        </div>
      </header>

      <main className="py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            {faqs.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        </div>
      </main>

      {/* Remove the Footer component from here */}
    </div>
  );
}

export default BMIFAQ;