import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronRight, Globe, Lightbulb, Microscope, Users } from 'lucide-react';
import { Helmet } from 'react-helmet';

// Import the images
import fritzImage from '../assets/images/fritz.jpeg';
import joseImage from '../assets/images/jose.jpeg';
import mohamedImage from '../assets/images/mohamed.jpeg';
import paulImage from '../assets/images/paul.jpeg';

// Import the panorama image
import panoramaImage from '../assets/images/Production_Panorama.jpg';

// Custom LinkedIn SVG icon
const LinkedInIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
  </svg>
);

function About() {
  const panoramaRef = useRef(null);

  useEffect(() => {
    const panoramaElement = panoramaRef.current;
    if (panoramaElement) {
      let scrollPosition = 0;
      const scrollSpeed = 0.5; // Adjust this value to change scroll speed

      const animatePanorama = () => {
        scrollPosition += scrollSpeed;
        if (scrollPosition >= panoramaElement.scrollWidth - panoramaElement.clientWidth) {
          scrollPosition = 0;
        }
        panoramaElement.scrollLeft = scrollPosition;
        requestAnimationFrame(animatePanorama);
      };

      animatePanorama();
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>About Endolastic | Innovative Endoscopic Solutions</title>
        <meta name="description" content="Learn about Endolastic's mission to revolutionize healthcare through innovative ligation technology for GERD, obesity, and diabetes treatments." />
        <meta name="keywords" content="Endolastic, about, medical innovation, endoscopic solutions, GERD, obesity, diabetes" />

        <link rel="canonical" href="https://www.endolastic.com/about" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.endolastic.com/about" />
        <meta property="og:title" content="About Endolastic | Innovative Endoscopic Solutions" />
        <meta property="og:description" content="Learn about Endolastic's mission to revolutionize healthcare through innovative ligation technology for GERD, obesity, and diabetes treatments." />
        <meta property="og:image" content="/OG-Endo.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.endolastic.com/about" />
        <meta property="twitter:title" content="About Endolastic | Innovative Endoscopic Solutions" />
        <meta property="twitter:description" content="Learn about Endolastic's mission to revolutionize healthcare through innovative ligation technology for GERD, obesity, and diabetes treatments." />
        <meta property="twitter:image" content="/OG-Endo.png" />
      </Helmet>

      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">About Endolastic</h1>
          <p className="text-xl md:text-2xl mb-8 max-w-3xl">
            Revolutionizing Healthcare Through Innovative Ligation Technology
          </p>
        </div>
      </header>

      <main className="py-16">
        <div className="container mx-auto px-4">
          <section id="our-story" className="mb-24">
            <h2 className="text-3xl font-bold mb-8">Our Story</h2>
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <p className="text-lg mb-4">
                  Founded in 2024, Endolastic emerged from a vision to transform the landscape of minimally invasive treatments. Our co-founders, Paul Robertson and Fritz Haller, recognized the potential of advanced ligation technology to address some of the most pressing challenges in gastroenterology and metabolic health.
                </p>
                <p className="text-lg">
                  Driven by a passion for innovation and a commitment to improving patient outcomes, Endolastic has rapidly evolved into a leading force in the development of groundbreaking endoscopic solutions for GERD, obesity, and Type 2 diabetes.
                </p>
              </div>
              <div className="relative h-64 md:h-80 overflow-hidden rounded-lg shadow-lg">
                <div 
                  ref={panoramaRef}
                  className="absolute top-0 left-0 w-full h-full overflow-x-scroll whitespace-nowrap scrollbar-hide"
                  style={{ scrollBehavior: 'smooth' }}
                >
                  <img
                    src={panoramaImage}
                    alt="Endolastic production facility"
                    className="h-full w-auto"
                  />
                </div>
              </div>
            </div>
          </section>

          <section id="our-mission" className="mb-24 bg-orange-50 rounded-2xl p-12">
            <h2 className="text-3xl font-bold mb-6 text-center">Our Mission</h2>
            <p className="text-xl text-center max-w-3xl mx-auto">
              At Endolastic, our mission is to revolutionize the treatment of chronic metabolic and gastrointestinal conditions through innovative, minimally invasive solutions. We strive to empower healthcare providers with cutting-edge technologies that enhance patient care, reduce procedural complexity, and improve quality of life.
            </p>
          </section>

          <section id="our-approach" className="mb-24">
            <h2 className="text-3xl font-bold mb-12 text-center">Our Approach</h2>
            <p className="text-xl text-center mb-12">Endolastic's approach is rooted in three core principles:</p>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                { icon: Lightbulb, title: "Innovation", description: "We continuously push the boundaries of what's possible in endoscopic treatments, leveraging our proprietary ligation technology to create novel solutions." },
                { icon: Microscope, title: "Simplicity", description: "Our devices are designed to be intuitive and user-friendly, reducing the learning curve for healthcare providers and improving accessibility for patients." },
                { icon: Users, title: "Patient-Centricity", description: "Every product and procedure we develop is guided by a commitment to enhancing patient outcomes and experiences." },
              ].map((principle, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-white rounded-lg shadow-md p-8 text-center"
                >
                  <principle.icon className="h-16 w-16 text-orange-500 mb-6 mx-auto" />
                  <h3 className="text-2xl font-semibold mb-4">{principle.title}</h3>
                  <p className="text-lg">{principle.description}</p>
                </motion.div>
              ))}
            </div>
          </section>

          <section id="our-technology" className="mb-24">
            <h2 className="text-3xl font-bold mb-8">Our Technology</h2>
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <p className="text-lg mb-6">
                  At the heart of Endolastic's innovations are our proprietary SafeTrap™ barrels and WindowLock™ bands. These technologies enable precise tissue capture and controlled pressure application, allowing for effective tissue remodeling without the need for cutting or ablation.
                </p>
                <h3 className="text-2xl font-semibold mb-4">Our flagship products include:</h3>
                <ul className="space-y-4">
                  {[
                    { name: "ARBM (Anti-Reflux Band Mucosectomy)", description: "A revolutionary treatment for GERD" },
                    { name: "EBG (Endoscopic Band Gastroplasty)", description: "An innovative approach to obesity management" },
                    { name: "EBDMR (Endoscopic Band Duodenal Mucosal Resurfacing)", description: "A groundbreaking solution for Type 2 diabetes" },
                  ].map((product, index) => (
                    <li key={index} className="flex items-start">
                      <ChevronRight className="h-6 w-6 text-orange-500 mr-2 flex-shrink-0 mt-1" />
                      <div>
                        <h4 className="font-semibold">{product.name}</h4>
                        <p>{product.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="relative h-96">
                <img
                  src="/placeholder.svg?height=600&width=800"
                  alt="Endolastic technology"
                  className="rounded-lg shadow-lg w-full h-full object-cover"
                />
              </div>
            </div>
          </section>

          <section id="leadership" className="mb-24">
            <h2 className="text-3xl font-bold mb-12 text-center">Leadership Team</h2>
            <div className="grid md:grid-cols-2 gap-12">
              {[
                { name: "Fritz Haller", role: "Co-founder & CEO", bio: "With over 20 years of experience in medical device innovation, Fritz leads Endolastic's strategic vision and operations.", image: fritzImage },
                { name: "Paul Robertson", role: "Co-founder & CPO", bio: "Paul brings extensive expertise in biomedical engineering, driving Endolastic's technological advancements and R&D efforts.", image: paulImage },
              ].map((leader, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md p-8 flex flex-col items-center text-center">
                  <div className="relative w-40 h-40 mb-6 rounded-full overflow-hidden">
                    <img
                      src={leader.image}
                      alt={leader.name}
                      className="absolute top-0 left-0 w-full h-full object-cover"
                    />
                  </div>
                  <h3 className="text-2xl font-semibold mb-2">{leader.name}</h3>
                  <p className="text-orange-600 mb-4">{leader.role}</p>
                  <p className="text-lg">{leader.bio}</p>
                </div>
              ))}
            </div>
          </section>

          <section id="partnerships" className="mb-24 bg-orange-50 rounded-2xl p-12">
            <h2 className="text-3xl font-bold mb-8 text-center">Key Partners</h2>
            <p className="text-xl text-center mb-12">
              Endolastic collaborates with leading medical institutions, research centers, and industry partners to advance our technologies and expand their global reach. Our key clinical advisors include:
            </p>
            <div className="grid md:grid-cols-2 gap-8">
              {[
                {
                  name: "Dr. Mohamed Abeid",
                  role: "Consultant Gastroenterologist and Bariatric Endoscopist",
                  image: mohamedImage,
                  linkedIn: "https://www.linkedin.com/in/mohamed-abeid-bb72169/",
                  summary: "Dr. Mohamed Abeid is a pioneering force in minimally invasive endoscopic treatments for obesity and GERD. With over 20 years of experience and more than 50,000 upper endoscopies performed, Dr. Abeid has been at the forefront of developing innovative techniques that align perfectly with Endolastic's mission. He brings unparalleled expertise to our Endoscopic Band Gastroplasty (EBG) technology. Dr. Abeid's groundbreaking work in endoscopic band ligation for weight loss has demonstrated significant results without the complications associated with more invasive procedures. His role as a consultant gastroenterologist and bariatric endoscopist at Cairo University, combined with his fellowships in prestigious international societies, positions him as a key advisor in Endolastic's quest to revolutionize metabolic health treatments."
                },
                {
                  name: "Dr. Jose M. Nieto",
                  role: "Advanced Therapeutic Endoscopist",
                  image: joseImage,
                  linkedIn: "https://www.linkedin.com/in/jose-n-470a696/",
                  summary: "Dr. Jose M. Nieto is a visionary in advanced therapeutic endoscopy, whose work aligns seamlessly with Endolastic's innovative approach to gastrointestinal treatments. As the pioneer of the Antireflux Band Mucosectomy (ARBM) technique, Dr. Nieto has demonstrated the power of band ligation technology in treating refractory GERD, directly complementing Endolastic's focus on minimally invasive solutions. His expertise in interventional pancreatobiliary endoscopy and his leadership in developing cutting-edge procedures have set new standards in patient care. With over 60 peer-reviewed publications and a track record of innovation in endoscopic treatments for complex GI conditions, Dr. Nieto brings invaluable insights to Endolastic's development of novel ligation-based therapies."
                },
              ].map((advisor, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md p-6">
                  <div className="flex items-center space-x-6 mb-4">
                    <div className="relative w-24 h-24 flex-shrink-0 rounded-full overflow-hidden">
                      <img
                        src={advisor.image}
                        alt={advisor.name}
                        className="absolute top-0 left-0 w-full h-full object-cover"
                      />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold">{advisor.name}</h3>
                      <p className="text-orange-600">{advisor.role}</p>
                      <a href={advisor.linkedIn} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                        <LinkedInIcon />
                      </a>
                    </div>
                  </div>
                  <p className="text-gray-700 leading-relaxed">{advisor.summary}</p>
                </div>
              ))}
            </div>
          </section>

          <section id="global-impact" className="mb-24">
            <h2 className="text-3xl font-bold mb-12 text-center">Global Impact</h2>
            <div className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-12">
              <Globe className="h-32 w-32 text-orange-500" />
              <p className="text-xl max-w-2xl text-center md:text-left">
                Endolastic's solutions are making a difference in patients' lives across 15 countries. We're committed to expanding access to our innovative treatments globally, partnering with distributors and healthcare providers to bring minimally invasive options to those who need them most.
              </p>
            </div>
          </section>

          <section id="future-vision" className="mb-24 bg-gradient-to-r from-orange-100 to-orange-200 rounded-2xl p-12">
            <h2 className="text-3xl font-bold mb-6 text-center">Our Vision for the Future</h2>
            <p className="text-xl text-center max-w-3xl mx-auto">
              As we look to the future, Endolastic remains committed to pushing the boundaries of endoscopic treatments. We envision a world where chronic metabolic and gastrointestinal conditions can be addressed through simple, effective, and minimally invasive procedures, dramatically improving patient outcomes and quality of life.
            </p>
          </section>

          <section id="join-us" className="text-center">
            <h2 className="text-3xl font-bold mb-6">Join Us in Revolutionizing Healthcare</h2>
            <p className="text-xl mb-8 max-w-3xl mx-auto">
              Whether you're a healthcare provider, potential partner, or talented individual looking to make a difference, we invite you to join us on our mission to transform patient care through innovation.
            </p>
            <a href="#" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-orange-600 hover:bg-orange-700 transition duration-300">
              Contact Us <ChevronRight className="ml-2 h-5 w-5" />
            </a>
          </section>
        </div>
      </main>
    </div>
  );
}

export default About;
