import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
  {
    question: "What is EBDMR, and how does it treat type 2 diabetes?",
    answer: "EBDMR (Endoscopic Band Duodenal Mucosal Resurfacing) is a minimally invasive endoscopic procedure that targets the duodenal mucosa. Using WindowLock™ bands, EBDMR induces controlled necrosis of dysfunctional duodenal cells, promoting tissue regeneration. The newly regenerated duodenal tissue restores normal metabolic signaling, potentially leading to improved insulin sensitivity and better glycemic control."
  },
  {
    question: "Why does duodenal tissue become dysfunctional in type 2 diabetes?",
    answer: "In type 2 diabetes, the duodenal mucosa undergoes changes due to chronic exposure to high-fat, high-sugar diets. This leads to hypertrophy (increased cell size) and endocrine hyperplasia (increased hormone-secreting cells), which disrupts the normal pathways that regulate glucose metabolism. These changes result in insulin resistance in the duodenum, exacerbating overall metabolic dysfunction and worsening glycemic control."
  },
  {
    question: "Why is targeting the duodenum clinically effective for improving insulin sensitivity?",
    answer: "The duodenum is the first site of nutrient contact after food leaves the stomach and plays a critical role in nutrient sensing and glucose metabolism. In type 2 diabetes, the signaling pathways in the duodenum become dysfunctional, contributing to insulin resistance. By targeting and resurfacing the duodenal mucosa, EBDMR helps reset these pathways, which can potentially restore insulin sensitivity and improve blood sugar regulation."
  },
  {
    question: "How does EBDMR work, and what are the procedural steps?",
    answer: "EBDMR involves the application of WindowLock™ bands using SafeTrap™ barrels to the duodenal wall, inducing controlled pressure necrosis and promoting regeneration. The steps include:\n1. Endoscope insertion: A gastroscope is used to access the duodenum.\n2. Tissue capture: SafeTrap™ barrels suction and capture the targeted mucosa.\n3. Band application: WindowLock™ bands are applied to induce necrosis.\n4. Tissue regeneration: Over the next few weeks, the dysfunctional duodenal tissue is replaced by new, healthy tissue, improving metabolic signaling."
  },
  {
    question: "How does EBDMR compare to other duodenal resurfacing technologies like Endogenex's ReCET™ or Fractyl's Revita?",
    answer: "Both Endogenex's ReCET™ and Fractyl's Revita use ablative technologies to resurface the duodenum. These methods involve either electrical pulses (ReCET™) or thermal ablation (Revita), which require specialized equipment and more complex procedures. EBDMR, by contrast, uses a mechanical ligation banding approach that avoids heat or electrical energy. This makes it a simpler, more cost-effective, and accessible procedure, as it leverages technology familiar to most endoscopists."
  },
  {
    question: "How does EBDMR promote tissue regeneration and metabolic improvement?",
    answer: "After the WindowLock™ bands are applied, they induce controlled pressure necrosis in the targeted duodenal tissue. This necrosis triggers a natural healing process, including the recruitment of stem cells and tissue regeneration. The regenerated tissue restores healthy metabolic signaling, potentially leading to better glucose regulation."
  },
  {
    question: "What are the expected outcomes of EBDMR, and how do they compare to results from other duodenal resurfacing studies?",
    answer: "Although EBDMR is still in the developmental stage and has not yet reached clinical trials, the aspirational benefits are informed by early data from similar duodenal resurfacing technologies like Revita and ReCET™. These studies have shown promising improvements in HbA1c reductions and insulin sensitivity. For example:\n• Revita's hydrothermal ablation resulted in an average reduction of HbA1c by 1.8% at 6 months.\n• ReCET™, using electrical pulses, demonstrated improvements in insulin sensitivity and weight reduction.\nEBDMR aims to achieve similar improvements in glycemic control, with the added benefit of being a simpler and potentially more accessible procedure."
  },
  {
    question: "Who is an ideal candidate for the EBDMR procedure?",
    answer: "EBDMR will likely be indicated for patients with type 2 diabetes who have not achieved adequate glycemic control despite medications and lifestyle changes. It is designed to offer a minimally invasive alternative to more complex procedures and could be a valuable option for those seeking to improve insulin sensitivity through a non-surgical approach."
  },
  {
    question: "What are the potential long-term benefits of EBDMR?",
    answer: "The long-term potential of EBDMR lies in its ability to reset the duodenum's metabolic signaling by regenerating healthy tissue. This could lead to sustained improvements in glycemic control, reducing reliance on medications like insulin and oral hypoglycemics. The procedure may also be repeatable if needed to maintain these benefits."
  },
  {
    question: "How does the use of conscious sedation in EBDMR enhance accessibility and affordability?",
    answer: "One of the advantages of EBDMR is that it can be performed under conscious sedation, which reduces the risks and recovery time associated with general anesthesia. This makes the procedure suitable for Ambulatory Surgical Centers (ASCs), which are more affordable and accessible compared to hospital settings. Conscious sedation opens up the opportunity for more widespread adoption of EBDMR, especially in outpatient care environments."
  }
];

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4 border-b border-gray-200 pb-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-xl font-semibold">{question}</h3>
        {isOpen ? (
          <ChevronUp className="w-6 h-6 text-orange-500" />
        ) : (
          <ChevronDown className="w-6 h-6 text-orange-500" />
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className="mt-2 text-gray-600">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

function DMRFAQ() {
  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">
            EBDMR Treatment FAQ
          </h1>
          <p className="text-xl md:text-2xl mb-8 max-w-3xl">
            Frequently Asked Questions for Physicians Regarding EBDMR with Endolastic Therapy for Type 2 Diabetes Management
          </p>
        </div>
      </header>

      <main className="py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            {faqs.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        </div>
      </main>

      {/* Remove the Footer component from here */}
    </div>
  );
}

export default DMRFAQ;